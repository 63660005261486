import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage, useFormik } from "formik";
import { FileDropZone } from "../../_metronic/layout/components/dropzone/FileDropZone";
import FormFooterButtons from "../components/FormFooterButtons";
import { Card } from "react-bootstrap";
import ImageUploader from "../components/ImageUploader";
import { GetUUID } from "../data/helpers/RandomNumGenerator";
import {
	admissionDetailName,
	admissionLevelName,
	childFriendlyDetail,
	instituteType,
	scholarshipFacilityList,
	schoolFacility,
	schoolLevel,
	studentLevel,
	studentServiceName,
	teacherTitleList,
	teacherTitleSecondaryList,
	ward,
	areaList,
} from "../data/institute";
import Select from "react-select";
import CustomSelectMulti from "../components/CustomSelectMulti";
const initData = {
	data: {
		uniqueIdentifier: GetUUID(),
		previewVisible: false,
		previewImage: "",
		loaded: 0,
		// other
		name: "",
		ward: "",
		elaani: "",
		nambari: "",
		schoolArea: "",
		schoolAreaType: "",
		isSchoolLandCertificate: "",
		benchCapacity: "",
		establishedAt: "",
		phone: "",
		website: "",
		email: "",
		address: "",
		schoolType: "",
		schoolLevel: "",
		latitude: "",
		isCberga: "",
		buildingKacchiCount: "",
		bulidingPakkiCount: "",
		roomKacchiCount: "",
		roomPakkiCount: "",
		mathCount: "",
		scienceCount: "",
		englishCount: "",
		longitude: "",
		childPlayGround: "",
		groundArea: "",
		groundAreaType: "",
		hasToilet: "",
		compoundWall: "",
		isLibrary: "",
		maleToilet: "",
		femaleToilet: "",
		staffToilet: "",
		maxTeacherSecondaryIndex: [1],
		maxStudentsIndex: [1],
		maxSpecIndex: 1,
		maxTeachersIndex: [1],
		maxYearIndex: 1,
		fiscalYear: [""],
		teacherSecondary: [[{ appointAs: "accepted", total: 0 }]],
		student: [[{ level: "totalStudent", male: 0, female: 0 }]],
		teacher: [[{ aapointAs: "portionWork", male: 0, female: 0 }]],
		facility: [
			{
				description: "",
				name: "disable_study",
			},
		],
		scholarshipFacility: [
			{
				name: "primaryCompleted",
				male: 0,
				female: 0,
			},
		],
		admissionDetail: [
			{
				level: "prePrimary",
				name: "admissionRate",
				rate: 0,
			},
		],
		studentService: [
			{
				serviceType: "primaryCompleted",
				male: 0,
				female: 0,
			},
		],
		childFriendlyDetail: [],
		studentLeavingSchool: [],
		schoolStaffDetail: [
			{
				name: "",
				coufiyat: "",
				designation: "",
				permanent: "",
				email: "",
				phone: 0,
			},
		],
		mathTeacher: 0,
		scienceTeacher: 0,
		englishTeacher: 0,
		maxFacilityIndex: 1,
		maxStudentServiceIndex: 1,
		maxScholarshipFacilityIndex: 1,
		maxChildFriendlyIndex: 1,
		maxAdmissionDetailIndex: 1,
		maxSchoolStaffDetailIndex: 1,
		enoughFurniture: "",
		classNineEducation: null,
		classNineMale: "",
		classNineFemale: "",
		classNineSubject: "",
		isEarthquakeResistance: "",
		isRetrofittingDone: "",
		isDisableClassProvide: "",
	},
};

const School = (props) => {
	const [apiData, setApiData] = useState({});
	const formik = useFormik({
		enableReinitialize: true,
		initialValues: apiData.data,
		validate: (values) => {
			const errors = {};
			// if (!values.name) {
			//     errors.name = 'Required';
			// }
			return errors;
		},
		onSubmit: (values, { setSubmitting }) => {
			props.submit(values);
		},
	});
	useEffect(() => {
		if (props.data) {
			setApiData((prevState) => {
				return {
					...prevState,
					data: {
						...props.data,
						teacherSecondary: props.data.teacherSecondary?.length
							? props.data.teacherSecondary
							: [[{ appointAs: "accepted", total: 0 }]],
						student: props.data.student?.length
							? props.data.student
							: [[{ level: "totalStudent", male: 0, female: 0 }]],
						teacher: props.data.teacher?.length
							? props.data.teacher
							: [[{ aapointAs: "portionWork", male: 0, female: 0 }]],
					},
				};
			});
		} else {
			setApiData(initData);
		}
		console.log(props);
	}, [props]);
	const getStudentCountFormSection = () => {
		const getSchoolLevel = (parentIndex) => {
			return (
				<>
					<div className="form-group row">
						<div className="col-lg-3">
							<a
								href="javascript:;"
								onClick={(e) => addRow("student", parentIndex)}
								className="btn btn-sm font-weight-bolder btn-light-primary"
							>
								<i className="la la-plus"></i>Add Row
							</a>
						</div>
					</div>
					{formik.values?.student[parentIndex]?.map((student, i) => (
						<div className="form-group row">
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Level</label>}
								<select
									className="form-control"
									id="level"
									name={`student[${parentIndex}][${i}].level`}
									onChange={formik.handleChange}
									value={formik.values?.student[parentIndex][i].level}
								>
									<option disabled selected>
										---------
									</option>
									{studentLevel?.map((level) => (
										<option value={level.value}>{level.nepali}</option>
									))}
								</select>
							</div>
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Male</label>}
								<input
									type="number"
									className="form-control"
									placeholder=""
									name={`student[${parentIndex}][${i}].male`}
									onChange={formik.handleChange}
									value={formik.values?.student[parentIndex][i].male}
								/>
							</div>
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Female</label>}
								<input
									type="number"
									className="form-control"
									placeholder=""
									name={`student[${parentIndex}][${i}].female`}
									onChange={formik.handleChange}
									value={formik.values?.student[parentIndex][i].female}
								/>
							</div>

							{i !== 0 && (
								<div className="col-md-3 d-flex justify-content-end">
									<a
										href="javascript:;"
										onClick={(e) => deleteFormRow("student", i, parentIndex)}
										className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
									>
										<i className="la la-trash-o"></i>Delete
									</a>
								</div>
							)}
						</div>
					))}
				</>
			);
		};
		const getStaffs = (parentIndex) => {
			return (
				<>
					<div className="form-group row">
						<div className="col-lg-3">
							<a
								href="javascript:;"
								onClick={(e) => addRow("teacherSecondary", parentIndex)}
								className="btn btn-sm font-weight-bolder btn-light-primary"
							>
								<i className="la la-plus"></i>Add Row
							</a>
						</div>
					</div>
					{formik.values?.teacherSecondary[parentIndex]?.map((teacher, i) => (
						<div className="form-group row">
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Level</label>}
								<select
									className="form-control"
									id="level"
									name={`teacherSecondary[${parentIndex}][${i}].level`}
									onChange={formik.handleChange}
									value={
										formik.values.teacherSecondary[parentIndex][i].appointAs
									}
								>
									<option disabled selected>
										---------
									</option>
									{teacherTitleSecondaryList?.map((level) => (
										<option value={level.value}>{level.nepali}</option>
									))}
								</select>
							</div>
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Total</label>}
								<input
									type="number"
									className="form-control"
									placeholder=""
									name={`teacherSecondary[${parentIndex}][${i}].total`}
									onChange={formik.handleChange}
									value={formik.values.teacherSecondary[parentIndex][i].total}
								/>
							</div>
							<div className="col-md-3"></div>

							{i !== 0 && (
								<div className="col-md-3 d-flex justify-content-end">
									<a
										href="javascript:;"
										onClick={(e) =>
											deleteFormRow("teacherSecondary", i, parentIndex)
										}
										className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
									>
										<i className="la la-trash-o"></i>Delete
									</a>
								</div>
							)}
						</div>
					))}
				</>
			);
		};
		const getTeachers = (parentIndex) => {
			return (
				<>
					<div className="form-group row">
						<div className="col-lg-3">
							<a
								href="javascript:;"
								onClick={(e) => addRow("teacher", parentIndex)}
								className="btn btn-sm font-weight-bolder btn-light-primary"
							>
								<i className="la la-plus"></i>Add Row
							</a>
						</div>
					</div>
					{formik.values?.teacher[parentIndex]?.map((teacher, i) => (
						<div className="form-group row">
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Level</label>}
								<select
									className="form-control"
									id="level"
									name={`teacher[${parentIndex}][${i}].appointAs`}
									onChange={formik.handleChange}
									value={formik.values?.teacher[parentIndex][i].appointAs}
								>
									<option disabled selected>
										---------
									</option>
									{teacherTitleList?.map((level) => (
										<option value={level.value}>{level.nepali}</option>
									))}
								</select>
							</div>
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Male</label>}
								<input
									type="number"
									className="form-control"
									placeholder=""
									name={`teacher[${parentIndex}][${i}].male`}
									onChange={formik.handleChange}
									value={formik.values?.teacher[parentIndex][i].male}
								/>
							</div>
							<div className="col-md-3">
								{i == 0 && <label className="form-control-label">Female</label>}
								<input
									type="number"
									className="form-control"
									placeholder=""
									name={`teacher[${parentIndex}][${i}].female`}
									onChange={formik.handleChange}
									value={formik.values?.teacher[parentIndex][i].female}
								/>
							</div>

							{i !== 0 && (
								<div className="col-md-3 d-flex justify-content-end">
									<a
										href="javascript:;"
										onClick={(e) => deleteFormRow("teacher", i, parentIndex)}
										className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
									>
										<i className="la la-trash-o"></i>Delete
									</a>
								</div>
							)}
						</div>
					))}
				</>
			);
		};
		return formik.values?.fiscalYear?.map((year, i) => (
			<Card className="mb-5">
				<Card.Body>
					<h3>विद्यार्थी संख्या</h3>
					<div className="form-group row">
						<div className="col-md-6">
							<label>मिति</label>
							<input
								type="date"
								className="form-control"
								id="example-date-input"
								placeholder="निर्माण भएको बर्ष"
								name={`fiscalYear[${i}]`}
								onChange={formik.handleChange}
								value={formik.values?.fiscalYear[i]}
							/>
						</div>
					</div>
					{getSchoolLevel(i)}
					<h3>कर्मचारी</h3>
					{getStaffs(i)}
					<h3>शिक्षक शिक्षीका</h3>
					{getTeachers(i)}
				</Card.Body>
			</Card>
		));
	};
	const addNewData = (keyToAdd) => {
		if (keyToAdd === "fiscalYear") {
			console.log(apiData);
			const student = [...formik.values.student, apiData.data.student[0]];
			const teacher = [...formik.values.teacher, apiData.data.teacher[0]];
			const teacherSecondary = [
				...formik.values.teacherSecondary,
				apiData.data.teacherSecondary[0],
			];
			const newValue = [...formik.values[keyToAdd], apiData.data[keyToAdd][0]];
			formik.setValues({
				...formik.values,
				student,
				teacher,
				teacherSecondary,
				[keyToAdd]: newValue,
			});
		}
	};
	const addRow = (keyToAdd, parentIndex) => {
		console.log(formik.values);

		const newValue = [...formik.values[keyToAdd]];
		newValue[parentIndex] = [
			...newValue[parentIndex],
			apiData.data[keyToAdd][0][0],
		];
		formik.setValues({
			...formik.values,
			[keyToAdd]: newValue,
		});
	};
	const deleteFormRow = (keyToManipulate, index, parentIndex) => {
		const updatedData = [...formik.values[keyToManipulate]];
		updatedData[parentIndex].splice(index, 1);
		formik.setValues({
			...formik.values,
			[keyToManipulate]: updatedData,
		});
	};
	const addRowOther = (keyToAdd) => {
		console.log(formik.values);

		const newValue = [...formik.values[keyToAdd], apiData.data[keyToAdd][0]];
		formik.setValues({
			...formik.values,
			[keyToAdd]: newValue,
		});
	};

	const deleteFormRowFacility = (index) => {
		const schoolFacility = formik.values?.facility;
		schoolFacility.splice(index, 1);
		formik.setValues({
			...formik.values,
			facility: schoolFacility,
		});
	};

	const deleteFormRowAdmission = (index) => {
		const admission = formik.values?.admissionDetail;
		admission.splice(index, 1);
		formik.setValues({
			...formik.values,
			admissionDetail: admission,
		});
	};

	const deleteFormRowStudentService = (index) => {
		const stdService = formik.values?.studentService;
		stdService.splice(index, 1);
		formik.setValues({
			...formik.values,
			studentService: stdService,
		});
	};

	const deleteFormRowScholarship = (index) => {
		const scholarship = formik.values?.scholarshipFacility;
		scholarship.splice(index, 1);
		formik.setValues({
			...formik.values,
			scholarshipFacility: scholarship,
		});
	};

	const deleteFormRowSchoolStaffDetail = (index) => {
		const detail = formik.values?.schoolStaffDetail;
		detail.splice(index, 1);
		formik.setValues({
			...formik.values,
			schoolStaffDetail: detail,
		});
	};
	const getSchoolFacility = () => {
		return (
			<>
				<h3>विद्यालय सुविधा</h3>
				<div className="form-group row">
					<div className="col-lg-3">
						<a
							href="javascript:;"
							onClick={(e) => addRowOther("facility")}
							className="btn btn-sm font-weight-bolder btn-light-primary"
						>
							<i className="la la-plus"></i>Add Row
						</a>
					</div>
				</div>
				{formik.values?.facility?.map((fac, index) => (
					<div className="form-group row">
						<div className="col-md-3">
							<label className="form-control-label">School Facility</label>
							<select
								className="form-control"
								id="level"
								name={`facility[${index}].name`}
								onChange={formik.handleChange}
								value={formik.values?.facility[index].name}
							>
								<option disabled selected>
									---------
								</option>
								{schoolFacility?.map((level) => (
									<option value={level.value}>{level.nepali}</option>
								))}
							</select>
							<ErrorMessage
								className="invalid-feedback"
								name="mathCount"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">Remarks</label>
							<textarea
								type="text"
								className="form-control"
								id="exampleTextarea"
								rows="3"
								name={`facility[${index}].description`}
								onChange={formik.handleChange}
								value={formik.values?.facility[index].description}
							></textarea>
							<ErrorMessage
								className="invalid-feedback"
								name="scienceCount"
								component="div"
							/>
						</div>

						{index !== 0 && (
							<div className="col-md-3 d-flex justify-content-end align-items-center">
								<a
									href="javascript:;"
									onClick={(e) => deleteFormRowFacility(index)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
								>
									<i className="la la-trash-o"></i>Delete
								</a>
							</div>
						)}
					</div>
				))}
			</>
		);
	};
	const getAdmissionRate = () => {
		return (
			<>
				<h3>भर्ना दर , निरन्तरता दर , सिकाई उपलब्धी दर</h3>
				<div className="form-group row">
					<div className="col-lg-3">
						<a
							href="javascript:;"
							onClick={(e) => addRowOther("admissionDetail")}
							className="btn btn-sm font-weight-bolder btn-light-primary"
						>
							<i className="la la-plus"></i>Add Row
						</a>
					</div>
				</div>
				{formik.values?.admissionDetail?.map((detail, i) => (
					<div className="form-group row">
						<div className="col-md-3">
							<label className="form-control-label">नाम</label>
							<select
								className="form-control"
								name={`admissionDetail[${i}].name`}
								onChange={formik.handleChange}
								value={formik.values?.admissionDetail[i].name}
							>
								<option disabled selected>
									---------
								</option>
								{admissionDetailName?.map((level) => (
									<option value={level.value}>{level.nepali}</option>
								))}
							</select>
							<ErrorMessage
								className="invalid-feedback"
								name="mathCount"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">स्तर</label>
							<select
								className="form-control"
								name={`admissionDetail[${i}].level`}
								onChange={formik.handleChange}
								value={formik.values?.admissionDetail[i].level}
							>
								<option disabled selected>
									---------
								</option>
								{admissionLevelName?.map((level) => (
									<option value={level.value}>{level.nepali}</option>
								))}
							</select>
							<ErrorMessage
								className="invalid-feedback"
								name="scienceCount"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">संख्या</label>
							<input
								type="number"
								className="form-control"
								placeholder=""
								name={`admissionDetail[${i}].rate`}
								onChange={formik.handleChange}
								value={formik.values?.admissionDetail[i].rate}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="mathCount"
								component="div"
							/>
						</div>

						{i !== 0 && (
							<div className="col-md-3 d-flex justify-content-end align-items-end">
								<a
									href="javascript:;"
									onClick={(e) => deleteFormRowAdmission(i)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
								>
									<i className="la la-trash-o"></i>Delete
								</a>
							</div>
						)}
					</div>
				))}
			</>
		);
	};
	const getStudentService = () => {
		return (
			<>
				<h3>
					विद्यार्थी उतिर्ण दर तथा विद्यार्थीले पाउने सेवा सुविधा सम्बन्धी
				</h3>
				<div className="form-group row">
					<div className="col-lg-3">
						<a
							href="javascript:;"
							onClick={(e) => addRowOther("studentService")}
							className="btn btn-sm font-weight-bolder btn-light-primary"
						>
							<i className="la la-plus"></i>Add Row
						</a>
					</div>
				</div>
				{formik.values?.studentService?.map((service, i) => (
					<div className="form-group row">
						<div className="col-md-3">
							<label className="form-control-label">नाम</label>
							<select
								className="form-control"
								name={`studentService[${i}].serviceType`}
								onChange={formik.handleChange}
								value={formik.values?.studentService[i].serviceType}
							>
								<option disabled selected>
									---------
								</option>
								{studentServiceName?.map((level) => (
									<option value={level.value}>{level.nepali}</option>
								))}
							</select>
							<ErrorMessage
								className="invalid-feedback"
								name="mathCount"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">छात्र</label>
							<input
								type="number"
								className="form-control"
								placeholder=""
								name={`studentService[${i}].male`}
								onChange={formik.handleChange}
								value={formik.values?.studentService[i].male}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="maleStudent"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">छात्रा</label>
							<input
								type="number"
								className="form-control"
								placeholder=""
								name={`studentService[${i}].female`}
								onChange={formik.handleChange}
								value={formik.values?.studentService[i].female}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="femaleStudent"
								component="div"
							/>
						</div>

						{i !== 0 && (
							<div className="col-md-3 d-flex justify-content-end align-items-end">
								<a
									href="javascript:;"
									onClick={(e) => deleteFormRowStudentService(i)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
								>
									<i className="la la-trash-o"></i>Delete
								</a>
							</div>
						)}
					</div>
				))}
			</>
		);
	};
	const getScholarshipDetail = () => {
		return (
			<>
				<h3>छात्रवृत्ति तथा लक्षित सुविधाको विवरण</h3>
				<div className="form-group row">
					<div className="col-lg-3">
						<a
							href="javascript:;"
							onClick={(e) => addRowOther("scholarshipFacility")}
							className="btn btn-sm font-weight-bolder btn-light-primary"
						>
							<i className="la la-plus"></i>Add Row
						</a>
					</div>
				</div>
				{formik.values?.scholarshipFacility?.map((scholarship, i) => (
					<div className="form-group row">
						<div className="col-md-3">
							<label className="form-control-label">नाम</label>
							<select
								className="form-control"
								name={`scholarshipFacility[${i}].name`}
								onChange={formik.handleChange}
								value={formik.values?.scholarshipFacility[i].name}
							>
								<option disabled selected>
									---------
								</option>
								{scholarshipFacilityList?.map((level) => (
									<option value={level.value}>{level.nepali}</option>
								))}
							</select>
							<ErrorMessage
								className="invalid-feedback"
								name="mathCount"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">छात्र</label>
							<input
								type="number"
								className="form-control"
								placeholder=""
								name={`scholarshipFacility[${i}].male`}
								onChange={formik.handleChange}
								value={formik.values?.scholarshipFacility[i].male}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="maleStudent"
								component="div"
							/>
						</div>
						<div className="col-md-3">
							<label className="form-control-label">छात्रा</label>
							<input
								type="number"
								className="form-control"
								placeholder=""
								name={`scholarshipFacility[${i}].female`}
								onChange={formik.handleChange}
								value={formik.values?.scholarshipFacility[i].female}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="femaleStudent"
								component="div"
							/>
						</div>
						{i !== 0 && (
							<div className="col-md-3 d-flex justify-content-end align-items-end">
								<a
									href="javascript:;"
									onClick={(e) => deleteFormRowScholarship(i)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
								>
									<i className="la la-trash-o"></i>Delete
								</a>
							</div>
						)}
					</div>
				))}
			</>
		);
	};
	const getSchoolStaffDetail = () => {
		return (
			<>
				<div className="form-group row">
					<div className="col-lg-3">
						<a
							href="javascript:;"
							onClick={(e) => addRowOther("schoolStaffDetail")}
							className="btn btn-sm font-weight-bolder btn-light-primary"
						>
							<i className="la la-plus"></i>Add Row
						</a>
					</div>
				</div>
				{formik.values?.schoolStaffDetail?.map((staffDetail, i) => (
					<div className="form-group row">
						<div className="col-md-2">
							<label className="form-control-label">नाम</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name={`schoolStaffDetail[${i}].name`}
								onChange={formik.handleChange}
								value={formik.values?.schoolStaffDetail[i].name}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="classNineMale"
								component="div"
							/>
						</div>
						<div className="col-md-2">
							<label className="form-control-label">पद /श्रेणी</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name={`schoolStaffDetail[${i}].designation`}
								onChange={formik.handleChange}
								value={formik.values?.schoolStaffDetail[i].designation}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="classNineMale"
								component="div"
							/>
						</div>
						<div className="col-md-2">
							<label className="form-control-label">स्थायी,करार</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name={`schoolStaffDetail[${i}].permanent`}
								onChange={formik.handleChange}
								value={formik.values?.schoolStaffDetail[i].permanent}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="classNineMale"
								component="div"
							/>
						</div>
						<div className="col-md-2">
							<label className="form-control-label">इमेल</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name={`schoolStaffDetail[${i}].email`}
								onChange={formik.handleChange}
								value={formik.values?.schoolStaffDetail[i].email}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="classNineMale"
								component="div"
							/>
						</div>
						<div className="col-md-2">
							<label className="form-control-label">फोन</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name={`schoolStaffDetail[${i}].phone`}
								onChange={formik.handleChange}
								value={formik.values?.schoolStaffDetail[i].phone}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="classNineMale"
								component="div"
							/>
						</div>
						<div className="col-md-1">
							<label className="form-control-label">कैफियत</label>
							<input
								type="text"
								className="form-control"
								placeholder=""
								name={`schoolStaffDetail[${i}].coufiyat`}
								onChange={formik.handleChange}
								value={staffDetail.coufiyat}
							/>
							<ErrorMessage
								className="invalid-feedback"
								name="classNineMale"
								component="div"
							/>
						</div>
						{i !== 0 && (
							<div className="col-md-1 d-flex justify-content-end align-items-end">
								<a
									href="javascript:;"
									onClick={(e) => deleteFormRowSchoolStaffDetail(i)}
									className="btn btn-sm font-weight-bolder btn-light-danger mt-1"
								>
									<i className="la la-trash-o"></i>Delete
								</a>
							</div>
						)}
					</div>
				))}
			</>
		);
	};
	return (
		<div>
			<Formik
				enableReinitialize
				initialValues={
					props.data
						? props.data
						: {
								name: "",
								ward: "",
								schoolArea: "",
								isSchoolLandCertificate: "",
								elaani: "",
								nambari: "",
								buildingKacchiCount: "",
								bulidingPakkiCount: "",
								establishedAt: "",
								mathCount: "",
								scienceCount: "",
								englishCount: "",
								roomKacchiCount: "",
								roomPakkiCount: "",
								benchCapacity: "",
								groundArea: "",
								maleToilet: "",
								femaleToilet: "",
								staffToilet: "",
								enoughFurniture: "",
								isCberga: "",
								compoundWall: "",
								isLibrary: "",
								teachers: "",
								staffs: "",
								students: "",
								phone: "",
								website: "",
								email: "",
								address: "",
								schoolType: "",
								schoolLevel: "",
								schoolClassLevel: "",
								latitude: "",
								longitude: "",
								classNineEducation: "",
								classNineSubject: "",
								classNineMale: "",
								classNineFemale: "",
								facility: "",
								admissionDetail: "",
								studentService: "",
								scholarshipFacility: "",
								childFriendlyDetail: "",
						  }
				}
				validate={(values) => {
					const errors = {};
					// if (!values.name) {
					//     errors.name = 'Required';
					// }
					// if (!values.ward) {
					//     errors.ward = 'Required';
					// }
					// if (!values.address) {
					//     errors.address = 'Required';
					// }
					// if (!values.schoolType) {
					//     errors.schoolType = 'Required';
					// }
					// if (!values.phone) {
					//     errors.phone = 'Required';
					// }
					// if (!values.website) {
					//     errors.website = 'Required';
					// }
					// if (!values.email) {
					//     errors.email = 'Required';
					// }
					// else
					if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
						errors.email = "Invalid email address";
					}
					// if (!values.ethnicity) {
					//     errors.ethnicity = 'Required';
					// }
					return errors;
				}}
				onSubmit={(values, { setSubmitting }) => {
					console.log(values);
					setTimeout(() => {
						props.submit(values);
						setSubmitting(false);
					}, 400);
				}}
			>
				{({
					values,
					touched,
					errors,
					dirty,
					isSubmitting,
					handleChange,
					handleBlur,
					handleSubmit,
					handleReset,
				}) => (
					<>
						<form className="form survey-form" onSubmit={handleSubmit}>
							<Card>
								<Card.Body>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">नाम</label>
											<input
												type="text"
												className="form-control"
												placeholder="नाम"
												name="name"
												onChange={handleChange}
												value={values.name}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="name"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">वडा नम्बर</label>
											<select
												className="form-control"
												id="ward"
												name="ward"
												onChange={handleChange}
												value={values.ward}
											>
												{ward?.map((woda) => (
													<option value={woda.value}>{woda.nepali}</option>
												))}
											</select>
											<ErrorMessage
												className="invalid-feedback"
												name="ward"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">ठेगाना</label>
											<input
												type="text"
												className="form-control"
												placeholder="ठेगाना"
												name="address"
												onChange={handleChange}
												value={values.address}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="address"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">
												{" "}
												विद्यालय प्रकार
											</label>
											<select
												className="form-control"
												id="schooltype"
												name="schoolType"
												onChange={handleChange}
												value={values.schoolType}
											>
												<option disabled selected>
													विद्यालय प्रकार
												</option>
												{instituteType?.map((institute) => (
													<option value={institute.value}>
														{institute.nepali}
													</option>
												))}
											</select>
											<ErrorMessage
												className="invalid-feedback"
												name="schoolType"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">फोन नम्बर</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter text"
												name="phone"
												onChange={handleChange}
												value={values.phone}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="phone"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">वेबसाईट</label>
											<input
												type="text"
												className="form-control"
												placeholder="Enter text"
												name="website"
												onChange={handleChange}
												value={values.website}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="website"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">ईमेल</label>
											<input
												type="email"
												className="form-control"
												placeholder="ईमेल"
												name="email"
												onChange={handleChange}
												value={values.email}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="email"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">अक्षांश</label>
											<input
												type="text"
												className="form-control"
												placeholder="ईमेल"
												name="longitude"
												onChange={handleChange}
												value={values.longitude}
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">देशान्तर</label>
											<input
												type="text"
												className="form-control"
												placeholder="ईमेल"
												name="lattitude"
												onChange={handleChange}
												value={values.lattitude}
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">विद्यालय तह</label>
											<select
												className="form-control"
												id="eduLevel"
												name="eduLevel"
												onChange={handleChange}
												value={values.eduLevel}
											>
												<option disabled selected>
													को शिक्षा
												</option>
												{schoolLevel?.map((school) => (
													<option value={school.value}>{school.nepali}</option>
												))}
											</select>
											<ErrorMessage
												className="invalid-feedback"
												name="eduLevel"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												विद्यालयले प्रयोग गरेको जग्गाको लालपुर्जा छ /छैन
											</label>
											<div class="radio-inline">
												<label className="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="isSchoolLandCertificate"
													/>
													<span></span>छ
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="isSchoolLandCertificate" />
													<span></span>
													छैन
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="isSchoolLandCertificate"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">
												विद्यालको नाममा रहेको जग्गा क्षेत्रफल
											</label>
											<input
												type="text"
												className="form-control"
												placeholder=""
												name="schoolArea"
												onChange={handleChange}
												value={values.schoolArea}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="schoolArea"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label>फोटो</label>
											<ImageUploader fileListContent={props.fileListContent} />
										</div>
									</div>
									<hr />
									<h3>विद्यालयले भोग चलन गरेको जग्गाको क्षेत्रफल</h3>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">ऐलानी</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="elaani"
												onChange={handleChange}
												value={values.elaani}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="elaani"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">नम्बरी</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="nambari"
												onChange={handleChange}
												value={values.nambari}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="nambari"
												component="div"
											/>
										</div>
									</div>
								</Card.Body>
							</Card>

							{getStudentCountFormSection()}
							<div className="form-group row">
								<div className="col-12 text-center">
									<a
										href="javascript:;"
										onClick={() => addNewData("fiscalYear")}
										className="btn btn-sm font-weight-bolder btn-primary"
									>
										<i className="la la-plus"></i>Add Section
									</a>
								</div>
							</div>
							<Card>
								<Card.Body>
									<h3>विषयगत शिक्षक संख्या</h3>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">गणित</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="mathCount"
												onChange={handleChange}
												value={values.mathCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="mathCount"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">विज्ञान</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="scienceCount"
												onChange={handleChange}
												value={values.scienceCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="scienceCount"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">अंग्रेजी</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="englishCount"
												onChange={handleChange}
												value={values.englishCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="englishCount"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">
												CBERGA कार्यनयाँन भएको ?
											</label>
											<div class="radio-inline">
												<label class="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="isCberga"
													/>
													<span></span>छ
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="isCberga" />
													<span></span>
													छैन
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="templeGroup"
												component="div"
											/>
										</div>
									</div>
									{getSchoolFacility()}
									{getAdmissionRate()}
									{getStudentService()}
									<hr />
									{getScholarshipDetail()}
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												बालमैत्री शिक्षा सम्बन्धि विवरण
											</label>
											<CustomSelectMulti
												value={formik.values?.childFriendlyDetail}
												isMulti={true}
												list={childFriendlyDetail}
												formik={formik}
												field="childFriendlyDetail"
											/>
											{/* <Select onChange={(e) => formik.setFieldValue("childFriendlyDetail", e.value)} value={formik.values?.childFriendlyDetail} isMulti={true} options={childFriendlyDetail?.map((child, i) => {
                                                return {
                                                    value: child.value,
                                                    label: child.nepali
                                                }
                                            })} /> */}
											<ErrorMessage
												className="invalid-feedback"
												name="englishCount"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">
												कक्षा ९ देखी प्राविधिक शिक्षा सन्चालन भएको
											</label>
											<div class="radio-inline">
												<label class="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="classNineEducation"
													/>
													<span></span>छ
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="classNineEducation" />
													<span></span>
													छैन
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="classNineEducation"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">छात्र</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="classNineMale"
												onChange={handleChange}
												value={values.classNineMale}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="classNineMale"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">छात्रा</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="classNineFemale"
												onChange={handleChange}
												value={values.classNineFemale}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="classNineFemale"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												छ भने कुन विषयको हो
											</label>
											<input
												type="text"
												className="form-control"
												placeholder=""
												name="classNineSubject"
												onChange={handleChange}
												value={values.classNineSubject}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="classNineSubject"
												component="div"
											/>
										</div>
									</div>
									<h3>भवन सम्बन्धि</h3>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">कच्ची संख्या</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="buildingKacchiCount"
												onChange={handleChange}
												value={values.buildingKacchiCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="buildingKacchiCount"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">पक्की संख्या</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="bulidingPakkiCount"
												onChange={handleChange}
												value={values.bulidingPakkiCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="bulidingPakkiCount"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												कच्ची कोठा संख्या
											</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="roomKacchiCount"
												onChange={handleChange}
												value={values.roomKacchiCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="roomKacchiCount"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">
												पक्की कोठा संख्या
											</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="roomPakkiCount"
												onChange={handleChange}
												value={values.roomPakkiCount}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="roomPakkiCount"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												बेन्चको क्षमता
											</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="benchCapacity"
												onChange={handleChange}
												value={values.benchCapacity}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="benchCapacity"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label>निर्माण भएको बर्ष</label>
											<input
												type="date"
												className="form-control"
												id="example-date-input"
												placeholder="निर्माण भएको बर्ष"
												name="establishedAt"
												onChange={handleChange}
												value={values.establishedAt}
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												आवश्यकता अनुसार्को फर्नीचर
											</label>
											<div class="radio-inline">
												<label class="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="hasNecessaryFurniture"
													/>
													<span></span>छ
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="hasNecessaryFurniture" />
													<span></span>
													छैन
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="hasNecessaryFurniture"
												component="div"
											/>
										</div>
									</div>
									<h3>खेल मैदान</h3>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">क्षेत्रफल</label>
											<input
												type="number"
												className="form-control"
												placeholder=""
												name="classNineMale"
												onChange={handleChange}
												value={values.classNineMale}
											/>
											<ErrorMessage
												className="invalid-feedback"
												name="classNineMale"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label>क्षेत्रफलको प्रकार</label>
											<select
												className="form-control"
												id="groundAreaType"
												name="groundAreaType"
												onChange={handleChange}
												value={values.areaType}
											>
												{areaList.map((alist) => (
													<option value={alist.value}>{alist.nepali}</option>
												))}
											</select>
											<ErrorMessage
												className="invalid-feedback"
												name="groundAreaType"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												बाल मैत्री मैदान
											</label>
											<div class="radio-inline">
												<label class="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="childPlayGround"
													/>
													<span></span>छ
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="childPlayGround" />
													<span></span>
													छैन
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="templeGroup"
												component="div"
											/>
										</div>
										<div className="col-md-6">
											<label className="form-control-label">
												पुस्तकालय र अध्ययन केन्द्र
											</label>
											<div class="radio-inline">
												<label class="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="isLibrary"
													/>
													<span></span>छ
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="isLibrary" />
													<span></span>
													छैन
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="templeGroup"
												component="div"
											/>
										</div>
									</div>
									<div className="form-group row">
										<div className="col-md-6">
											<label className="form-control-label">
												कम्पाउण्ड वालको किसिम
											</label>
											<div class="radio-inline">
												<label class="radio radio-rounded">
													<input
														type="radio"
														checked="checked"
														name="compoundWall"
													/>
													<span></span>
													कच्ची
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="compoundWall" />
													<span></span>
													पक्की
												</label>
												<label class="radio radio-rounded">
													<input type="radio" name="compoundWall" />
													<span></span>
													तारबार
												</label>
											</div>
											<ErrorMessage
												className="invalid-feedback"
												name="compoundWall"
												component="div"
											/>
										</div>
									</div>
									<hr />
									{getSchoolStaffDetail()}

									<div className="card-footer text-right">
										<FormFooterButtons />
									</div>
								</Card.Body>
							</Card>
						</form>
					</>

					// <Form>
					//     <Field type="email" name="email" />
					//     <ErrorMessage className="invalid-feedback" name="email" component="div" />
					//     <Field type="password" name="password" />
					//     <ErrorMessage className="invalid-feedback" name="password" component="div" />
					//     <button type="submit" disabled={isSubmitting}>
					//         Submit
					//     </button>
					// </Form>
				)}
			</Formik>
		</div>
	);
};

export default School;
