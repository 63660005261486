import React, { useEffect, useRef, useState } from "react";
import {
	Button,
	Card,
	Dropdown,
	Badge,
	Form,
	Tabs,
	Tab,
	Spinner,
} from "react-bootstrap";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import {
	autoSuggetionString,
	bloodgroup,
	genderList,
	houseNeutralSearch,
	HouseSearchIndex,
	isBoolean,
	mapData,
	rangeValue,
	searchOption,
	tableTitles,
	trueFalse,
} from "../data/advanceSearch";
import Slider from "../components/Range";
import {
	getAdvancedSearchResultsFamily,
	getAdvancedSearchResultsHouse,
	getContactNumbersByHouse,
	getResultByHouse,
	getResultByName,
	getResultByPhone,
} from "../services/searchAPI";
import { getAuthToken } from "../../redux/axiosHandler";
import { CustomPaginationActionsTable } from "../components/CustomPaginationActionsTable";
import ExportCSV from "../components/ExportCSV";
import { useStateCallback } from "../data/helpers/useStateCallBack";
import ExcelExport from "../components/ExcelExport";
import Loader from "../components/Loader";
import { selectedLanguage, ward } from "../data/institute";
import { gaupalikaNepali } from "../data/global";
import { toast } from "react-toastify";
import HomeSurveyDetail from "./HomeSurveyDetail";
import Table from "rc-table";
import Pagination from "../components/Pagination";
import { answererRelation } from "../data/houseSurvey";
import { NavLink, Link } from "react-router-dom";
import NoData from "../components/NoData";
import SmsModal from "../components/SmsModal";
import DataTable from "react-data-table-component";
const mapDataKeys = Object.keys(mapData);
const columns = [
	{
		title: "क्र.स",
		key: "id",
		dataIndex: "id",
		width: null,
	},
	{
		title: "वडा न.",
		key: "wardNumber",
		dataIndex: "wardNumber",
		width: null,
	},
	{
		title: "घर न.",
		key: "",
		dataIndex: "",
		width: null,
		render: function(val) {
			return (
				<Link
					className="menu-link menu-toggle"
					to={`/home-survey/detail/${val.houseId}`}
					target="_blank"
				>
					{val.houseNumber}
				</Link>
			);
		},
	},
	{
		title: "नाम",
		key: "fullName",
		dataIndex: "fullName",
		width: null,
	},
	{
		title: "लिङ्ग",
		key: "gender",
		dataIndex: "gender",
		width: null,
	},
	{
		title: "उमेर",
		key: "age",
		dataIndex: "age",
		width: null,
	},
	{
		title: "घरमुलीसंगको नाता",
		key: "answererRelation",
		dataIndex: "answererRelation",
		width: null,
	},
	{
		title: "फोन नम्बर",
		key: "contact",
		dataIndex: "contact",
		width: null,
	},
];
const SearchPage = () => {
	const initialApiState = {
		page: 0,
		limit: 20,
		abroad: {},
		agriculture: {},
		agricultureDetail: {},
		beeFishSilk: {},
		business: {},
		death: {},
		disable: {},
		economics: {},
		education: {},
		family: {},
		house: {},
		houseFacility: {},
		insurance: {},
		land: {},
		liveStock: {},
		occupation: {},
		rent: {},
		skill: {},
		welfare: {},
		womenChild: {},
		isHouseOnly: true,
	};
	const [selectedCategory, setSelectedCategory] = useState({
		nepali: "विस्तृत  जानकारी खोज्नुहोस्",
	});
	const [selectedDetail, setSelectedDetail] = useState("house");
	const [options, setOptions] = useState({});
	const [showDropDown, setShowDropDown] = useState(false);
	const [apiData, setApiData] = useStateCallback(initialApiState);
	const [tableData, setTableData] = useState([]);
	const [tableColumns, setTableColumns] = useState([]);
	// const [houseOrFamily, setHouseFamilySearch] = useState("");
	const [excelHeaders, setExcelHeaders] = useState([]);
	const [selectedDisplayData, setSelectedDisplayData] = useState([]);
	const [exportLoading, setExportLoading] = useState(false);
	const [totalDataCount, setTotalDataCount] = useState(0);
	const [page, setPage] = useState(0);
	const [loading, setLoading] = useState(false);
	const [excelData, setExcelData] = useState([]);
	const [searchType, setSearchType] = useState("4");
	const [correspondingDetail, setCorrespondingDetail] = useState("house");
	const [normalSearchData, setNormalSearchData] = useState([]);
	const [apiCalled, setApiCalled] = useState(false);
	const [searchInputs, setSearchInputs] = useState({
		name: "",
		contact: "",
		house: "",
	});
	const [selectedWard, setSelectedWard] = useState(0);
	const [houseDetail, setHouseDetail] = useState(undefined);
	const [totalPages, setTotalPages] = useState(0);
	const [displayData, setDisplayData] = useState([]);
	const [smsLoading, setSmsLoading] = useState(false);
	const [phoneNumbersForSms, setphoneNumbersForSms] = useState([]);
	const [show, setShow] = useState(false);
	const isFirstRun = useRef(true);
	const filterFunctionHouse = {
		true: (suggestion) =>
			HouseSearchIndex.includes(suggestion.value) ||
			(houseNeutralSearch.includes(suggestion.value) &&
				!Object.keys(apiData[selectedDetail]).includes(suggestion.value)),
		false: (suggestion) =>
			!HouseSearchIndex.includes(suggestion.value) &&
			!Object.keys(apiData[selectedDetail]).includes(suggestion.value),
	};
	const filterFunctionAll = {
		true: (suggestion) =>
			HouseSearchIndex.includes(suggestion.value) ||
			houseNeutralSearch.includes(suggestion.value),
		false: (suggestion) => !HouseSearchIndex.includes(suggestion.value),
	};
	const getSuggestionsList = (selectedDetailOption) =>
		autoSuggetionString.filter(
			filterFunctionHouse[selectedDetailOption === "house"]
		);
	const getAllSuggestionsList = (selectedDetailOption) =>
		autoSuggetionString.filter(
			filterFunctionAll[selectedDetailOption === "house"]
		);
	const categories = getSuggestionsList(selectedDetail);
	const allCategories = getAllSuggestionsList(selectedDetail);
	const handleCategorySelect = (value) => {
		const currCorrespondingDetail =
			mapDataKeys.find((i) => {
				return mapData[i].includes(value);
			}) || "house";
		setCorrespondingDetail(currCorrespondingDetail);

		const newOptions = searchOption[value] || {};
		setOptions(newOptions);
		setSelectedCategory(categories.find((i) => i.value == value));
		if (!selectedDisplayData.find((i) => i.value == value)) {
			setSelectedDisplayData([
				...selectedDisplayData,
				categories.find((i) => i.value == value),
			]);
		}
	};
	useEffect(() => {
		const apiDataKeys = Object.keys(apiData);
		const excludeNonObjectKeys = ["page", "limit", "isHouseOnly", "house"];
		const filteredApiDataKeys = apiDataKeys.filter(
			(i) => !excludeNonObjectKeys.includes(i)
		);
		let keyCount = 0;
		filteredApiDataKeys.forEach((key) => {
			const innerKeys = Object.keys(apiData[key]);
			if (innerKeys.length > 0) {
				keyCount++;
			}
		});
		if (keyCount > 0) {
			setApiData({
				...apiData,
				isHouseOnly: false,
			});
		} else {
			setApiData({
				...apiData,
				isHouseOnly: true,
			});
		}
	}, [selectedDisplayData]);
	const handleRemoveCategory = (e, value) => {
		e.stopPropagation();
		const currDetail =
			mapDataKeys.find((i) => {
				return mapData[i].includes(value);
			}) || "house";
		const newDispData = selectedDisplayData.filter((i) => i.value !== value);
		let newApiData = apiData[currDetail];
		const keyInApiData = value?.includes("agricultureProductionSalesType")
			? "agricultureDetail.title"
			: value.replace("_", ".");
		console.log([keyInApiData], newApiData[keyInApiData]);
		if (newApiData[keyInApiData]) {
			delete newApiData[keyInApiData];
		}
		setSelectedDisplayData(newDispData);
		setShowDropDown(!showDropDown);
		setApiData({
			...apiData,
			[currDetail]: newApiData,
		});
		if (options.length || isRange(selectedCategory?.value)) {
			setSelectedCategory({ nepali: "विस्तृत  जानकारी खोज्नुहोस्" });
			setOptions({});
		}
	};
	const filteringDone = () => {
		setShowDropDown(false);
		setOptions({});
	};
	const toggleDropDown = (e) => {
		e.stopPropagation();
		// e.preventDefault();
		setShowDropDown(!showDropDown);
		if (options.length || isRange(selectedCategory?.value)) {
			setSelectedCategory({ nepali: "विस्तृत  जानकारी खोज्नुहोस्" });
			setOptions({});
		}
	};
	const tabs = [
		{
			id: "1",
			title: "घर न. द्वारा ",
		},
		{
			id: "2",
			title: "नामद्वारा",
		},
		{
			id: "3",
			title: "फोन न.द्वारा",
		},
		{
			id: "4",
			title: "वृस्तित खोजी",
		},
	];
	const checkInt = (val) => {
		if (parseInt(val)) return parseInt(val);
		if (val == "false") {
			return false;
		}
		if (val == "true") {
			return true;
		}
		return val;
	};
	const handleClear = () => {
		setSelectedCategory({ nepali: "विस्तृत  जानकारी खोज्नुहोस्" });
		setOptions({});
		setApiData(initialApiState);
		setSelectedDisplayData([]);
		setTableColumns([]);
		setTableData([]);
		setSearchInputs({
			name: "",
			contact: "",
			house: "",
		});
		setHouseDetail(undefined);
		setApiCalled(false);
	};
	const handleChildCheck = (e) => {
		if (e.target.checked) {
			let newSelectedDisplayData = selectedDisplayData;
			let selectedDispDatum = newSelectedDisplayData.find(
				(i) => i.value === selectedCategory.value
			);
			if (!selectedDispDatum.children) {
				selectedDispDatum.children = [];
			}
			let result = newSelectedDisplayData.map((el) => {
				return el.value === selectedCategory.value
					? {
							...el,
							children: [...el.children, e.target.name],
					  }
					: el;
			});
			setSelectedDisplayData(result);
			const selectedCatInApiData = selectedCategory.value?.includes(
				"agricultureProductionSalesType"
			)
				? "agricultureDetail.title"
				: selectedCategory.value.replace("_", ".");
			if (apiData[correspondingDetail][selectedCatInApiData]) {
				let checkedArr =
					apiData[correspondingDetail][selectedCatInApiData]?.$in;
				if (checkedArr) {
					checkedArr.push(checkInt(e.target.value));
				} else {
					checkedArr = [checkInt(e.target.value)];
				}
				setApiData({
					...apiData,
					[correspondingDetail]: {
						...apiData[correspondingDetail],
						[selectedCatInApiData]: {
							$in: checkedArr,
						},
					},
				});
			} else {
				let checkedArr = [checkInt(e.target.value)];
				setApiData({
					...apiData,
					[correspondingDetail]: {
						...apiData[correspondingDetail],
						[selectedCatInApiData]: {
							$in: checkedArr,
						},
					},
				});
			}
		} else {
			let newSelectedDisplayData = selectedDisplayData;
			let result = newSelectedDisplayData.map((el) => {
				return el.value === selectedCategory.value
					? {
							...el,
							children: el.children.filter((i) => i !== e.target.name),
					  }
					: el;
			});
			setSelectedDisplayData(result);
			const selectedCatInApiData = selectedCategory.value?.includes(
				"agricultureProductionSalesType"
			)
				? "agricultureDetail.title"
				: selectedCategory.value.replace("_", ".");
			let checkedArr = apiData[correspondingDetail][
				selectedCatInApiData
			]?.$in.filter((i) => i !== checkInt(e.target.value));
			if (checkedArr.length) {
				setApiData({
					...apiData,
					[correspondingDetail]: {
						...apiData[correspondingDetail],
						[selectedCatInApiData]: {
							$in: checkedArr,
						},
					},
				});
			} else {
				let newHouseApiData = apiData[correspondingDetail];
				delete newHouseApiData[selectedCatInApiData];
				setApiData({
					...apiData,
					selectedDetailOption: newHouseApiData,
				});
			}
		}
	};
	const handleRadioCheck = (e) => {
		setApiData({
			...apiData,
			[correspondingDetail]: {
				...apiData[correspondingDetail],
				[selectedCategory.value]: checkInt(e.target.value),
			},
		});
		let newSelectedDisplayData = selectedDisplayData;
		let selectedDispDatum = newSelectedDisplayData.find(
			(i) => i.value === selectedCategory.value
		);
		if (!selectedDispDatum.children) {
			selectedDispDatum.children = [];
		}
		let result = newSelectedDisplayData.map((el) => {
			return el.value === selectedCategory.value
				? {
						...el,
						children: [e.target.name],
				  }
				: el;
		});
		setSelectedDisplayData(result);
	};
	const handleSearchTypeSelect = (e) => {
		setSearchType(e);
		handleClear();
	};
	useEffect(() => {}, [searchType]);
	const isRange = (value) => {
		return rangeValue.includes(value);
	};
	const handleCompChange = (name, value) => {
		setApiData({
			...apiData,
			[correspondingDetail]: {
				...apiData[correspondingDetail],

				[name]: {
					$gte: value[0],
					$lte: value[1],
				},
			},
		});
		let newSelectedDisplayData = selectedDisplayData;
		let selectedDispDatum = newSelectedDisplayData.find(
			(i) => i.value === selectedCategory.value
		);
		if (!selectedDispDatum.range) {
			selectedDispDatum.range = [];
		}
		let result = newSelectedDisplayData.map((el) => {
			return el.value === selectedCategory.value
				? {
						...el,
						range: [value[0], value[1]],
				  }
				: el;
		});
		setSelectedDisplayData(result);
	};
	const age = (
		<Slider
			text="उमेर"
			style={{ width: "250px" }}
			name="age"
			min={0}
			max={120}
			color="#bf4040"
			// defaultValue={state.age}
			range
			onChange={(newValue) => {
				handleCompChange("age", newValue);
			}}
		/>
	);

	const nonAgriIncome = (
		<Slider
			text="वार्षिक आम्दानी"
			style={{ width: "250px" }}
			name="nonAgriIncome"
			min={0}
			max={500000}
			color="#bf4040"
			// defaultValue={state.nonAgriIncome}
			range
			onChange={(value) => {
				handleCompChange("nonAgriIncome", value);
			}}
		/>
	);

	const agriExpense = (
		<Slider
			text="वार्षिक खर्च"
			style={{ width: "250px" }}
			name="agriExpense"
			min={0}
			max={500000}
			color="#bf4040"
			// defaultValue={state.agriExpense}
			range
			onChange={(value) => {
				handleCompChange("agriExpense", value);
			}}
		/>
	);
	const agriIncome = (
		<Slider
			text="वार्षिक आम्दानी"
			style={{ width: "250px" }}
			name="agriIncome"
			min={0}
			max={500000}
			color="#bf4040"
			// defaultValue={state.agriIncome}
			range
			onChange={(value) => {
				handleCompChange("agriIncome", value);
			}}
		/>
	);
	const nonAgriExpense = (
		<Slider
			text="वार्षिक खर्च"
			style={{ width: "250px" }}
			name="nonAgriExpense"
			min={0}
			max={500000}
			color="#bf4040"
			// defaultValue={state.nonAgriExpense}
			range
			onChange={(value) => {
				handleCompChange("nonAgriExpense", value);
			}}
		/>
	);
	const getRangeDropDown = () => {
		switch (selectedCategory?.value) {
			case "age":
				return age;
				break;

			case "nonAgriIncome":
				return nonAgriIncome;
				break;

			case "nonAgriExpense":
				return nonAgriExpense;
				break;
			case "agriIncome":
				return agriIncome;
				break;
			case "agriExpense":
				return agriExpense;
				break;

			default:
				break;
		}
	};
	const getDropDownList = () => {
		if (options?.length) {
			console.log(
				apiData[correspondingDetail][selectedCategory.value]?.$in,
				options
			);
			return (
				<Dropdown.Menu
					style={{ width: "100%", maxHeight: "55vh", overflowY: "auto" }}
					className="pt-8"
				>
					{!isBoolean.includes(selectedCategory.value) &&
						options.map((child, index) => {
							return selectedCategory.value.includes(
								"agricultureProductionSalesType"
							) ? (
								<Form.Group className="ml-5" key={index}>
									<Form.Check
										name={child.nepali}
										onChange={handleChildCheck}
										label={child.nepali}
										value={child.value}
										checked={apiData[correspondingDetail][
											"agricultureDetail.title"
										]?.$in?.includes(child.value)}
									/>
								</Form.Group>
							) : (
								<Form.Group className="ml-5" key={index}>
									<Form.Check
										name={child.nepali}
										onChange={handleChildCheck}
										label={child.nepali}
										value={child.value}
										checked={apiData[correspondingDetail][
											selectedCategory.value
										]?.$in?.includes(child.value)}
									/>
								</Form.Group>
							);
						})}
					{isBoolean.includes(selectedCategory.value) &&
						trueFalse.map((child, index) => {
							return (
								<Form.Group className="ml-5" key={index}>
									<Form.Check
										name={child.nepali}
										onChange={handleRadioCheck}
										label={child.nepali}
										value={child.value}
										checked={
											apiData[correspondingDetail][selectedCategory.value] ===
											child.value
										}
									/>
								</Form.Group>
							);
						})}

					<Button
						size="sm"
						className="ml-5"
						variant="success"
						onClick={filteringDone}
					>
						Done
					</Button>
				</Dropdown.Menu>
			);
		} else {
			if (isRange(selectedCategory?.value)) {
				return (
					<Dropdown.Menu
						style={{ width: "100%", maxHeight: "55vh", overflowY: "auto" }}
						className="px-4"
					>
						{getRangeDropDown()}
					</Dropdown.Menu>
				);
			}
			return (
				<Dropdown.Menu
					style={{ width: "100%", maxHeight: "55vh", overflowY: "auto" }}
				>
					{categories.map((category, index) => {
						return (
							<Dropdown.Item key={index} as="button" eventKey={category.value}>
								{category.nepali}
							</Dropdown.Item>
						);
					})}
				</Dropdown.Menu>
			);
		}
	};
	const handleDetailSelect = (val) => {
		handleClear();
		setApiData(initialApiState);
		setSelectedDetail(val);
		if (options.length || isRange(selectedCategory?.value)) {
			setSelectedCategory({ nepali: "विस्तृत  जानकारी खोज्नुहोस्" });
			setOptions({});
		}
		setSelectedDisplayData([]);
	};
	const handleResultData = (res) => {
		const tableData = res.data.results.map((datum) => {
			if (datum.bloodGroup?.bloodType) {
				return {
					...datum,
					bloodGroup: {
						bloodType: bloodgroup.find(
							(i) => i.value === datum.bloodGroup?.bloodType
						)
							? bloodgroup.find((i) => i.value === datum.bloodGroup?.bloodType)[
									selectedLanguage
							  ]
							: "",
					},
				};
			}
			return datum;
		});
		// data for new table with all data loaded
		const tableColumns = res.data.columns
			.filter((i) => i !== "contactNumber")
			.map((column) => {
				return {
					name: tableTitles.find((i) => i.value === column)
						? tableTitles.find((i) => i.value === column).nepali
						: "",
					selector: (row) => {
						if (column.includes(".")) {
							const keys = column.split(".");
							return row[keys[0]] && row[keys[0]][keys[1]];
						}
						if (column === "houseNumber") {
							return (
								<Link
									className="menu-link menu-toggle"
									to={`/home-survey/detail/${row["house"]}`}
									target="_blank"
								>
									{row[column]}
								</Link>
							);
						}
						return row[column];
					},
				};
			});
		let results;
		if (selectedDetail === "family" && apiData) {
			if (
				apiData.education.educationLevel ||
				apiData.education.nineToTwelve ||
				apiData.education.oneToEightClass ||
				apiData.disable.type ||
				apiData.disable.condition ||
				apiData.disable.hasCard ||
				apiData.disable.cardType ||
				apiData.occupation.levelOneJob ||
				apiData.occupation.levelTwoJob ||
				apiData.skill.title ||
				apiData.abroad.foreignCountry ||
				apiData.abroad.abroadReason
			) {
				results = tableData.filter(
					(eachD) => eachD && eachD.house && eachD._id && eachD.family
				);
			} else {
				results = tableData.filter(
					(eachD) => eachD && eachD.house && eachD._id
				);
			}
		} else if (selectedDetail === "house") {
			results = tableData.filter((eachD) => eachD && eachD.house && eachD._id);
		}

		setTableData(results);
		setTableColumns(tableColumns);
		setExcelHeaders(
			res.data.columns.map((column) => ({
				label: tableTitles.find((i) => i.value == column)?.nepali || "",
				key: column,
			}))
		);
		if (res.data.count) {
			setTotalDataCount(res.data.count);
		}
	};
	const handleSearch = () => {
		setLoading(true);
		setShowDropDown(false);
		if (selectedDetail == "house") {
			getAdvancedSearchResultsHouse(apiData)
				.then((res) => {
					handleResultData(res);
					setApiCalled(true);
					setLoading(false);
				})
				.catch((err) => {
					setApiCalled(true);
					setLoading(false);
				});
		} else {
			getAdvancedSearchResultsFamily(apiData)
				.then((res) => {
					setApiCalled(true);
					setLoading(false);
					handleResultData(res);
				})
				.catch((err) => {
					setApiCalled(true);
					setLoading(false);
				});
		}
		// getAuthToken()
	};
	const handleSearchClicked = () => {
		setPage(0);
		if (apiData.page == 0) {
			handleSearch();
		} else {
			setApiData({
				...apiData,
				page: 0,
			});
		}
	};
	const handlePillClick = (value) => {
		setShowDropDown(true);
		const newOptions = searchOption[value] || {};
		setOptions(newOptions);
		setSelectedCategory(allCategories.find((i) => i.value == value));
		if (!selectedDisplayData.find((i) => i.value == value)) {
			setSelectedDisplayData([
				...selectedDisplayData,
				allCategories.find((i) => i.value == value),
			]);
		}
	};
	const handleChangePage = (pageNumber) => {
		setApiData({
			...apiData,
			page: pageNumber,
		});
	};
	useEffect(() => {
		if (isFirstRun.current) {
			isFirstRun.current = false;
			return;
		}
		handleSearch();
	}, [apiData.page]);
	const exportData = () => {
		setExportLoading(true);
		const apiDataForExport = {
			...apiData,
			page: null,
			isHouseOnly: null,
		};
		if (selectedDetail == "house") {
			getAdvancedSearchResultsHouse(apiDataForExport)
				.then((res) => {
					handleExportExcel(res.data);
					setExportLoading(false);
					// handleResultData(res);
				})
				.catch((err) => {
					setExportLoading(false);
				});
		} else {
			getAdvancedSearchResultsFamily(apiDataForExport)
				.then((res) => {
					handleExportExcel(res.data);
					setExportLoading(false);
					// handleResultData(res);
				})
				.catch((err) => {
					setExportLoading(false);
				});
		}
	};
	const handleExportExcel = (data) => {
		setExcelData(data.results);
		document.getElementById("exportElement").click();
	};
	const handleWardChange = (e) => {
		setSelectedWard(e.target.value);
	};
	const searchByHouse = () => {
		setLoading(true);
		setHouseDetail(undefined);
		console.log(searchInputs);
		getResultByHouse(searchInputs.house)
			.then((res) => {
				setApiCalled(true);
				setLoading(false);
				if (res.data.success) {
					setHouseDetail(res.data);
				}
			})
			.catch((err) => {
				toast.error("An error occured");
				setApiCalled(true);
				setLoading(false);
			});
	};
	const searchByName = () => {
		setLoading(true);
		getResultByName(searchInputs.name, selectedWard)
			.then((res) => {
				setApiCalled(true);
				setLoading(false);
				if (res.data.length > 0) {
					const dataForTable = res.data.map((datum, i) => {
						return {
							id: i + 1,
							wardNumber: datum.house.wardNumber,
							houseNumber: datum.house.houseNumber,
							fullName: datum.fullName,
							gender: genderList.find((i) => i.value === datum.gender)
								? genderList.find((i) => i.value === datum.gender)[
										selectedLanguage
								  ]
								: "",
							age: datum.age,
							answererRelation: answererRelation.find(
								(i) => i.value === datum.answererRelation
							)
								? answererRelation.find(
										(i) => i.value === datum.answererRelation
								  )[selectedLanguage]
								: "",
							contact: datum.contactNumber,
							houseId: datum.house._id,
						};
					});
					const dispData = dataForTable.slice(0, 10);
					const totalPage = Math.ceil(dataForTable.length / 10);
					setTotalPages(totalPage);
					setDisplayData(dispData);
					setNormalSearchData(dataForTable);
				}
			})
			.catch((err) => {
				toast.error("An error occured");
				setApiCalled(true);
				setLoading(false);
			});
	};
	const searchByPhone = () => {
		setLoading(true);
		getResultByPhone(searchInputs.contact, selectedWard)
			.then((res) => {
				setApiCalled(true);
				setLoading(false);
				if (res.data.length > 0) {
					const dataForTable = res.data.map((datum, i) => {
						return {
							id: i + 1,
							wardNumber: datum.house.wardNumber,
							houseNumber: datum.house.houseNumber,
							fullName: datum.fullName,
							gender: genderList.find((i) => i.value === datum.gender)
								? genderList.find((i) => i.value === datum.gender)[
										selectedLanguage
								  ]
								: "",
							age: datum.age,
							answererRelation: answererRelation.find(
								(i) => i.value === datum.answererRelation
							)
								? answererRelation.find(
										(i) => i.value === datum.answererRelation
								  )[selectedLanguage]
								: "",
							contact: datum.contactNumber,
							houseId: datum.house._id,
						};
					});
					const dispData = dataForTable.slice(0, 10);
					const totalPage = Math.ceil(dataForTable.length / 10);
					setTotalPages(totalPage);
					setDisplayData(dispData);
					setNormalSearchData(dataForTable);
				}
			})
			.catch((err) => {
				toast.error("An error occured");
				setApiCalled(true);
				setLoading(false);
			});
	};
	useEffect(() => {}, [displayData]);
	const handleSearchInputChange = (e) => {
		const newSearchInput = {
			...searchInputs,
			[e.target.name]: e.target.value,
		};
		setSearchInputs(newSearchInput);
	};
	const handlePageChange = (val) => {
		const start = val * 10;
		const end = start + 10;
		const dispData = normalSearchData.slice(start, end);
		setDisplayData(dispData);
	};
	const isValidPhone = (phoneNumber) =>
		phoneNumber &&
		phoneNumber.toString().length === 10 &&
		phoneNumber.toString().startsWith("98");
	const sendSms = () => {
		setSmsLoading(true);
		setShow(true);
		const apiDataForExport = {
			...apiData,
			page: null,
			isHouseOnly: false,
		};
		getAdvancedSearchResultsFamily(apiDataForExport)
			.then((res) => {
				const allHouseIds = res.data.results.map((datum) => {
					return datum.house;
				});
				const uniqueHouseIds = [...new Set(allHouseIds)];
				getContactNumbersByHouse(uniqueHouseIds)
					.then((res) => {
						const validPhoneNumbers = res.data
							.filter((contact) => {
								return (
									contact.houseOwnerMobile &&
									isValidPhone(contact.houseOwnerMobile)
								);
							})
							.map((contactObj) => {
								return contactObj.houseOwnerMobile;
							});
						setSmsLoading(false);
						const uniquePhoneNumbers = [...new Set(validPhoneNumbers)];
						setphoneNumbersForSms(uniquePhoneNumbers);
					})
					.catch((err) => {
						toast.error("An error occured while fetching phone numbers");
						setSmsLoading(false);
					});
				// handleResultData(res);
			})
			.catch((err) => {
				toast.error("An error occured while fetching phone numbers");
				setSmsLoading(false);
			});
	};
	const getSearchSection = () => {
		switch (searchType) {
			case "1":
				return (
					<div className="row">
						<div className="col-lg-12">
							<h4>घर न.</h4>
							<div className="row">
								<div className="col-lg-6">
									<div class="form-group">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">
													<i class="fas fa-home"></i>
												</span>
											</div>
											<input
												type="number"
												class="form-control"
												placeholder="घर न."
												value={searchInputs.house}
												name="house"
												onChange={handleSearchInputChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<Button
										variant="primary"
										size="sm"
										className="mr-3"
										onClick={searchByHouse}
									>
										<span className="svg-icon menu-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/General/Search.svg"
												)}
												alt=""
											/>
										</span>
										खोज्नुहोस{" "}
									</Button>
								</div>
							</div>
							{loading && <Loader loading={loading} />}
							{!loading && houseDetail && apiCalled && (
								<HomeSurveyDetail houseDetail={houseDetail} />
							)}
							{!loading && !houseDetail && apiCalled && <NoData />}
						</div>
					</div>
				);
			case "2":
				return (
					<div className="row">
						<div className="col-lg-12">
							<h4>नाम</h4>
							<div className="row">
								<div className="col-lg-4">
									<div class="form-group">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">
													<i class="far fa-user"></i>
												</span>
											</div>
											<input
												type="text"
												class="form-control"
												placeholder="नाम"
												value={searchInputs.name}
												name="name"
												onChange={handleSearchInputChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<select
										className="form-control"
										id="ward"
										onChange={(e) => handleWardChange(e)}
									>
										<option value={0}>{gaupalikaNepali}</option>
										{ward.map((woda) => (
											<option value={woda.value}>
												{woda[selectedLanguage]}
											</option>
										))}
									</select>
								</div>
								<div className="col-lg-4">
									<Button
										variant="primary"
										size="sm"
										className="mr-3"
										onClick={searchByName}
									>
										<span className="svg-icon menu-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/General/Search.svg"
												)}
												alt=""
											/>
										</span>
										खोज्नुहोस{" "}
									</Button>
								</div>
							</div>
							{loading && <Loader loading={loading} />}
							{!loading && normalSearchData.length > 0 && apiCalled && (
								<>
									<Table
										className="table table-hover"
										columns={columns}
										data={displayData}
										data-filter-control="true"
									/>
									<Pagination
										pages={totalPages}
										handlePageChange={handlePageChange}
									/>
								</>
							)}
							{!loading && normalSearchData.length === 0 && apiCalled && (
								<NoData />
							)}
						</div>
					</div>
				);
			case "3":
				return (
					<div className="row">
						<div className="col-lg-12">
							<h4>फोन न.</h4>
							<div className="row">
								<div className="col-lg-4">
									<div class="form-group">
										<div class="input-group">
											<div class="input-group-prepend">
												<span class="input-group-text">
													<i class="fas fa-phone-square-alt"></i>
												</span>
											</div>
											<input
												type="number"
												class="form-control"
												placeholder="फोन न."
												value={searchInputs.contact}
												name="contact"
												onChange={handleSearchInputChange}
											/>
										</div>
									</div>
								</div>
								<div className="col-lg-4">
									<select
										className="form-control"
										id="ward"
										onChange={(e) => handleWardChange(e)}
									>
										<option value={0}>{gaupalikaNepali}</option>
										{ward.map((woda) => (
											<option value={woda.value}>
												{woda[selectedLanguage]}
											</option>
										))}
									</select>
								</div>
								<div className="col-lg-4">
									<Button
										variant="primary"
										size="sm"
										className="mr-3"
										onClick={searchByPhone}
									>
										<span className="svg-icon menu-icon">
											<SVG
												src={toAbsoluteUrl(
													"/media/svg/icons/General/Search.svg"
												)}
												alt=""
											/>
										</span>
										खोज्नुहोस{" "}
									</Button>
								</div>
							</div>
							{loading && <Loader loading={loading} />}
							{!loading && normalSearchData.length > 0 && apiCalled && (
								<>
									<Table
										className="table table-hover"
										columns={columns}
										data={displayData}
										data-filter-control="true"
									/>
									<Pagination
										pages={totalPages}
										handlePageChange={handlePageChange}
									/>
								</>
							)}
							{!loading && normalSearchData.length === 0 && apiCalled && (
								<NoData />
							)}
						</div>
					</div>
				);
			case "4":
				return (
					<>
						<div className="row ">
							<div className="col-12 mx-4 mb-4">
								<Tabs
									size="sm"
									variant="pills"
									defaultActiveKey="house"
									id="uncontrolled-tab-example"
									onSelect={handleDetailSelect}
								>
									<Tab eventKey="house" title="घर"></Tab>
									<Tab eventKey="family" title="परिवार"></Tab>
								</Tabs>
							</div>
						</div>
						<div className="row">
							<div className="col-lg-9">
								<div className="dropdown">
									<Dropdown onSelect={handleCategorySelect} show={showDropDown}>
										<Dropdown.Toggle
											block
											variant="secondary btn-sm text-left"
											onClick={toggleDropDown}
											id="dropdown-basic"
										>
											{selectedCategory.nepali}
										</Dropdown.Toggle>
										{getDropDownList()}
									</Dropdown>
								</div>
							</div>
							<div className="col-lg-3">
								<Button
									variant="primary"
									size="sm"
									className="mr-3"
									onClick={handleSearchClicked}
								>
									<span className="svg-icon menu-icon">
										<SVG
											src={toAbsoluteUrl("/media/svg/icons/General/Search.svg")}
											alt=""
										/>
									</span>
									खोज्नुहोस{" "}
								</Button>
								<Button variant="light" size="sm" onClick={handleClear}>
									<span className="svg-icon menu-icon">
										<SVG
											src={toAbsoluteUrl(
												"/media/svg/icons/Navigation/Close.svg"
											)}
											alt=""
										/>
									</span>
									मेटाउनुहोस्
								</Button>
							</div>
						</div>
						<div className="mt-3 d-flex flex-wrap">
							{selectedDisplayData.map((displayDatum) => (
								<Badge
									className="bg-light-primary text-primary search-result mr-2"
									key={displayDatum.value}
									onClick={(e) => handlePillClick(displayDatum.value)}
								>
									{displayDatum.nepali}:{" "}
									{displayDatum.children?.length !== 0 &&
										displayDatum.children?.map((child, index) => (
											<span key={index}>
												{child}
												{displayDatum.children?.length !== index + 1
													? ", "
													: ""}
											</span>
										))}
									{displayDatum.range && (
										<span>
											{displayDatum.range[0]} - {displayDatum.range[1]}
										</span>
									)}
									<i
										className="text-primary icon-nm ml-3 flaticon-close"
										onClick={(e) => handleRemoveCategory(e, displayDatum.value)}
									></i>
								</Badge>
							))}
						</div>
						{loading && <Loader loading={loading} />}
						{!loading &&
							tableData.length > 0 &&
							tableColumns.length > 0 &&
							apiCalled && (
								<>
									<div className="d-flex justify-content-end">
										<h6 className="mr-3 text-primary pt-2">
											प्रतिफल:{" "}
											<span className="text-dark-65">
												{totalDataCount}{" "}
												{selectedDetail === "house" ? "घर" : "जना"}
											</span>
										</h6>
										{selectedDetail !== "house" && (
											<>
												<Button
													variant="light-primary"
													size="sm"
													className="float-right d-flex mr-3"
													onClick={sendSms}
													disabled={smsLoading}
												>
													<span className="svg-icon menu-icon">
														<i className="icon-lg fas fa-mail-bulk"></i>
													</span>
													SMS
												</Button>
												<SmsModal
													setShow={setShow}
													show={show}
													setphoneNumbersForSms={setphoneNumbersForSms}
													phoneNumbersForSms={phoneNumbersForSms}
													loading={smsLoading}
												/>
											</>
										)}

										<Button
											variant="light-success"
											size="sm"
											className="float-right d-flex"
											onClick={exportData}
											disabled={exportLoading}
										>
											{exportLoading ? (
												<Spinner
													animation="grow"
													variant="success"
													size="sm"
													className="mr-2"
												/>
											) : (
												<span className="svg-icon menu-icon">
													<i className="icon-lg far fa-file-excel"></i>
												</span>
											)}
											Export
										</Button>
									</div>
									<ExcelExport headers={excelHeaders} data={excelData} />
									{/* <ExportCSV data={tableData} headers={excelHeaders} apiData={apiData} detail={selectedDetail} label={"Export"} /> */}

									<DataTable
										columns={tableColumns}
										data={tableData}
										pagination
									/>
									{/* Old custom table with pagination */}
									{/* <CustomPaginationActionsTable
										page={page}
										setPage={setPage}
										handleChangePage={handleChangePage}
										total={totalDataCount}
										tableData={tableData}
										columnData={tableColumns}
									/> */}
								</>
							)}
						{!loading && tableData.length === 0 && apiCalled && <NoData />}
					</>
				);
		}
	};
	return (
		<>
			<Card style={{ minHeight: "76vh" }}>
				<Card.Header className="d-flex justify-content-between">
					<h4 className="font-weight-bolder" style={{ minWidth: "50%" }}>
						घर विस्तृत खोजी
					</h4>
					<Tabs
						size="sm"
						variant="pills"
						defaultActiveKey={searchType}
						id="uncontrolled-tab-example"
						onSelect={handleSearchTypeSelect}
					>
						{tabs.map((tab) => {
							return (
								<Tab key={tab.id} eventKey={tab.id} title={tab.title}></Tab>
							);
						})}
					</Tabs>
				</Card.Header>
				<Card.Body>{getSearchSection()}</Card.Body>
			</Card>
		</>
	);
};

export default SearchPage;
