export const allToleName = [
	// 1
	{
		nepali: "गढी टोल",
		english: "gadi_tole",
		value: "gadi_tole",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman_tole",
		value: "musalman_tole",
	},
	{
		nepali: "पासवान टोल",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "ब्रह्मपुरी बजार",
		english: "brahma_puri",
		value: "brahma_puri",
	},
	{
		nepali: "सहनी टोल",
		english: "sahani_tole",
		value: "sahani_tole",
	},
	{
		nepali: "धरमपुर टोल",
		english: "brahmapuri_bajar",
		value: "brahmapuri_bajar",
	},
	{
		nepali: "महारानी टोल",
		english: "maharani_tole",
		value: "maharani_tole",
	},
	{
		nepali: "राम टोल",
		english: "ram_tole",
		value: "ram_tole",
	},
	{
		nepali: "कलवार टोल",
		english: "kalwar_tole",
		value: "kalwar_tole",
	},
	// 2
	{
		nepali: "गढी टोल",
		english: "gadi_tole",
		value: "gadi_tole",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman_tole",
		value: "musalman_tole",
	},
	{
		nepali: "पासवान टोल",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "ब्रह्मपुरी बजार",
		english: "brahma_puri",
		value: "brahma_puri",
	},
	{
		nepali: "मोहनपुर टोल",
		english: "mohanpur_tole",
		value: "mohanpur_tole",
	},
	{
		nepali: "सहनी टोल",
		english: "sahani_tole",
		value: "sahani_tole",
	},
	{
		nepali: "धरमपुर टोल",
		english: "rato_pul",
		value: "rato_pul",
	},
	{
		nepali: "महारानी टोल",
		english: "maharani_tole",
		value: "maharani_tole",
	},
	{
		nepali: "राम टोल",
		english: "ram_tole",
		value: "ram_tole",
	},
	// 3
	{
		nepali: "भेल्ली",
		english: "bheli",
		value: "bheli",
	},
	{
		nepali: "ठुलो मठ टोल",
		english: "thulo_mot_tole",
		value: "thulo_mot_tole",
	},
	{
		nepali: "मुसहरी टोल",
		english: "musari_tole",
		value: "musari_tole",
	},
	{
		nepali: "हनुमान मन्दिर",
		english: "hanuman_mandir",
		value: "hanuman_mandir",
	},
	{
		nepali: "सलेश टोल",
		english: "salesh_tole",
		value: "salesh_tole",
	},
	{
		nepali: "चमार टोल",
		english: "chamar_tole",
		value: "chamar_tole",
	},
	{
		nepali: "कुर्मी टोल",
		english: "kurmi_tole",
		value: "kurmi_tole",
	},
	{
		nepali: "सोनार टोल",
		english: "sonar_tole",
		value: "sonar_tole",
	},
	{
		nepali: "यादव टोल",
		english: "yadav_tole",
		value: "yadav_tole",
	},
	{
		nepali: "महारानी टोल",
		english: "maharani_tole",
		value: "maharani_tole",
	},
	{
		nepali: "शाह टोल",
		english: "sah_tole",
		value: "sah_tole",
	},
	{
		nepali: "मुस्लिम टोल",
		english: "muslim_tole",
		value: "muslim_tole",
	},
	{
		nepali: "कापर टोल",
		english: "kapar_tole",
		value: "kapar_tole",
	},
	// 4
	{
		nepali: "त्रिभुवननगर",
		english: "tribhuvan_tole",
		value: "tribhuvan_tole",
	},
	{
		nepali: "सेख टोल",
		english: "shek_tole",
		value: "shek_tole",
	},
	{
		nepali: "खैरा टोल",
		english: "khaira_tole",
		value: "khaira_tole",
	},
	{
		nepali: "शंकर टोल",
		english: "shankar_tole",
		value: "shankar_tole",
	},
	{
		nepali: "साहु मुहल्ला",
		english: "sahu_muhalla",
		value: "sahu_muhalla",
	},
	{
		nepali: "डीहवार चौक",
		english: "dihabir_tole",
		value: "dihabir_tole",
	},
	{
		nepali: "यादव टोल",
		english: "yadav_tole",
		value: "yadav_tole",
	},
	{
		nepali: "कोइरी टोल",
		english: "koiri_tole",
		value: "koiri_tole",
	},
	{
		nepali: "खतबे टोल",
		english: "khatabe_tole",
		value: "khatabe_tole",
	},
	// 5
	{
		nepali: "सखुअवा",
		english: "sakhuwaba_tole",
		value: "sakhuwaba_tole",
	},
	{
		nepali: "पडरिया टोल",
		english: "padariya_tole",
		value: "padariya_tole",
	},
	// 6
	{
		nepali: "पिपरा",
		english: "pipara",
		value: "pipara",
	},
	{
		nepali: "भुताहा",
		english: "bhutaha",
		value: "bhutaha",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman",
		value: "musalman",
	},
	{
		nepali: "मुसहर  टोल",
		english: "musar",
		value: "musar",
	},
	{
		nepali: "पासवान टोल पिपरा",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "राम टोल पिपरा",
		english: "rame_pipara_tole",
		value: "rame_pipara_tole",
	},
	{
		nepali: "नोकेल्ह्वा उत्तर",
		english: "nokalwa_uttar",
		value: "nokalwa_uttar",
	},
	{
		nepali: "नोकेल्ह्वा दक्षिण",
		english: "nokalwa_dakhinn",
		value: "nokalwa_dakhinn",
	},
	// 7
	{
		nepali: "नोकेल्ह्वा",
		english: "nokalwa",
		value: "nokalwa",
	},
	{
		nepali: "मोहन्गंज",
		english: "mohangunj",
		value: "mohangunj",
	},
	{
		nepali: "मुसहर  टोल",
		english: "mushar_tole",
		value: "mushar_tole",
	},
	{
		nepali: "पिडारी टोल",
		english: "pidari_tole",
		value: "pidari_tole",
	},
	{
		nepali: "खोरिया टोल",
		english: "khoriya_tole",
		value: "khoriya_tole",
	},
	{
		nepali: "रामपुर",
		english: "rampur",
		value: "rampur",
	},
];

export const toleWardOne = [
	{
		nepali: "गढी टोल",
		english: "gadi_tole",
		value: "gadi_tole",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman_tole",
		value: "musalman_tole",
	},
	{
		nepali: "पासवान टोल",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "ब्रह्मपुरी बजार",
		english: "brahma_puri",
		value: "brahma_puri",
	},
	{
		nepali: "सहनी टोल",
		english: "sahani_tole",
		value: "sahani_tole",
	},
	{
		nepali: "धरमपुर टोल",
		english: "brahmapuri_bajar",
		value: "brahmapuri_bajar",
	},
	{
		nepali: "महारानी टोल",
		english: "maharani_tole",
		value: "maharani_tole",
	},
	{
		nepali: "राम टोल",
		english: "ram_tole",
		value: "ram_tole",
	},
	{
		nepali: "कलवार टोल",
		english: "kalwar_tole",
		value: "kalwar_tole",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardTwo = [
	{
		nepali: "गढी टोल",
		english: "gadi_tole",
		value: "gadi_tole",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman_tole",
		value: "musalman_tole",
	},
	{
		nepali: "पासवान टोल",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "ब्रह्मपुरी बजार",
		english: "brahma_puri",
		value: "brahma_puri",
	},
	{
		nepali: "मोहनपुर टोल",
		english: "mohanpur_tole",
		value: "mohanpur_tole",
	},
	{
		nepali: "सहनी टोल",
		english: "sahani_tole",
		value: "sahani_tole",
	},
	{
		nepali: "धरमपुर टोल",
		english: "rato_pul",
		value: "rato_pul",
	},
	{
		nepali: "महारानी टोल",
		english: "maharani_tole",
		value: "maharani_tole",
	},
	{
		nepali: "राम टोल",
		english: "ram_tole",
		value: "ram_tole",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardThree = [
	{
		nepali: "भेल्ली",
		english: "bheli",
		value: "bheli",
	},
	{
		nepali: "ठुलो मठ टोल",
		english: "thulo_mot_tole",
		value: "thulo_mot_tole",
	},
	{
		nepali: "मुसहरी टोल",
		english: "musari_tole",
		value: "musari_tole",
	},
	{
		nepali: "हनुमान मन्दिर",
		english: "hanuman_mandir",
		value: "hanuman_mandir",
	},
	{
		nepali: "सलेश टोल",
		english: "salesh_tole",
		value: "salesh_tole",
	},
	{
		nepali: "चमार टोल",
		english: "chamar_tole",
		value: "chamar_tole",
	},
	{
		nepali: "कुर्मी टोल",
		english: "kurmi_tole",
		value: "kurmi_tole",
	},
	{
		nepali: "सोनार टोल",
		english: "sonar_tole",
		value: "sonar_tole",
	},
	{
		nepali: "यादव टोल",
		english: "yadav_tole",
		value: "yadav_tole",
	},
	{
		nepali: "महारानी टोल",
		english: "maharani_tole",
		value: "maharani_tole",
	},
	{
		nepali: "शाह टोल",
		english: "sah_tole",
		value: "sah_tole",
	},
	{
		nepali: "मुस्लिम टोल",
		english: "muslim_tole",
		value: "muslim_tole",
	},
	{
		nepali: "कापर टोल",
		english: "kapar_tole",
		value: "kapar_tole",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardFour = [
	{
		nepali: "त्रिभुवननगर",
		english: "tribhuvan_tole",
		value: "tribhuvan_tole",
	},
	{
		nepali: "सेख टोल",
		english: "shek_tole",
		value: "shek_tole",
	},
	{
		nepali: "खैरा टोल",
		english: "khaira_tole",
		value: "khaira_tole",
	},
	{
		nepali: "शंकर टोल",
		english: "shankar_tole",
		value: "shankar_tole",
	},
	{
		nepali: "साहु मुहल्ला",
		english: "sahu_muhalla",
		value: "sahu_muhalla",
	},
	{
		nepali: "डीहवार चौक",
		english: "dihabir_tole",
		value: "dihabir_tole",
	},
	{
		nepali: "यादव टोल",
		english: "yadav_tole",
		value: "yadav_tole",
	},
	{
		nepali: "कोइरी टोल",
		english: "koiri_tole",
		value: "koiri_tole",
	},
	{
		nepali: "खतबे टोल",
		english: "khatabe_tole",
		value: "khatabe_tole",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardFive = [
	{
		nepali: "सखुअवा",
		english: "sakhuwaba_tole",
		value: "sakhuwaba_tole",
	},
	{
		nepali: "पडरिया टोल",
		english: "padariya_tole",
		value: "padariya_tole",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardSix = [
	{
		nepali: "पिपरा",
		english: "pipara",
		value: "pipara",
	},
	{
		nepali: "भुताहा",
		english: "bhutaha",
		value: "bhutaha",
	},
	{
		nepali: "मुसलमान टोल",
		english: "musalman",
		value: "musalman",
	},
	{
		nepali: "मुसहर  टोल",
		english: "musar",
		value: "musar",
	},
	{
		nepali: "पासवान टोल पिपरा",
		english: "paswan_tole",
		value: "paswan_tole",
	},
	{
		nepali: "राम टोल पिपरा",
		english: "rame_pipara_tole",
		value: "rame_pipara_tole",
	},
	{
		nepali: "नोकेल्ह्वा उत्तर",
		english: "nokalwa_uttar",
		value: "nokalwa_uttar",
	},
	{
		nepali: "नोकेल्ह्वा दक्षिण",
		english: "nokalwa_dakhinn",
		value: "nokalwa_dakhinn",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];

export const toleWardSeven = [
	{
		nepali: "नोकेल्ह्वा",
		english: "nokalwa",
		value: "nokalwa",
	},
	{
		nepali: "मोहन्गंज",
		english: "mohangunj",
		value: "mohangunj",
	},
	{
		nepali: "मुसहर  टोल",
		english: "mushar_tole",
		value: "mushar_tole",
	},
	{
		nepali: "पिडारी टोल",
		english: "pidari_tole",
		value: "pidari_tole",
	},
	{
		nepali: "खोरिया टोल",
		english: "khoriya_tole",
		value: "khoriya_tole",
	},
	{
		nepali: "रामपुर",
		english: "rampur",
		value: "rampur",
	},
	{
		nepali: "अन्य",
		english: "others",
		value: "others",
	},
];
